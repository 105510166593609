<template>
  <en-drawer :model-value="modelValue" title="投诉信息" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')" :loading="form.loading">
      <en-form-item label="投诉日期" prop="complaintDate">
        <en-date-picker v-model="form.data.complaintDate" class="w-full" value-format="YYYY-MM-DD" :disabled="form.disabled"></en-date-picker>
      </en-form-item>
      <en-form-item label="回访人员" prop="visitor.id">
        <select-maintain
          v-model="form.data.visitor"
          :ajax="{ action: 'GET /enocloud/common/user', params: (params, quickSearch) => (params.payload = { quickSearch }) }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="投诉来源" prop="way">
        <select-maintain
          v-model="form.data.way"
          :ajax="{ action: 'GET /enocloud/common/hint/:hintTypeStr', params: (params) => (params.paths = ['CMPLTBY']) }"
          :props="{ label: 'name', value: 'name' }"
          :disabled="form.disabled"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="投诉对象" prop="targets">
        <select-maintain
          v-model="form.data.targets"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['CPLTGT']) }"
          :props="{ label: 'message', value: '' }"
          :disabled="form.disabled"
          multiple
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="有效投诉" prop="valid.code">
        <select-maintain
          v-model="form.data.valid"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['CPLVAL']) }"
          :props="{ label: 'message', value: '' }"
          :disabled="form.disabled"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="投诉内容" prop="complaintContent">
        <en-input v-model="form.data.complaintContent" :disabled="form.disabled"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :disabled="form.data.status?.code === 'UI'" :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudServiceDefinitions['ComplaintDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (id: number | undefined) => ['number', 'undefinded'].includes(typeof id)
  },

  watch: {
    modelValue: {
      async handler() {
        if (this.modelValue) {
          Object.assign(this.form.data, this.data)
          if (this.form.data.id) this.form.get()
        } else {
          this.form.init()
        }
      }
    }
  },

  config: {
    children: {
      form: {
        rules: {
          complaintDate: { required: true, message: '请填写投诉日期' },
          'visitor.id': { required: true, message: '请选择回访人员' },
          way: { required: true, message: '请填写投诉来源' },
          'valid.code': { required: true, message: '请选择有效投诉' },
          targets: { type: 'array', required: true, message: '请选择投诉对象' },
          complaintContent: { required: true, message: '请填写投诉内容' }
        },
        ajax: {
          get: {
            action: 'GET /enocloud/service/complaint/:complaintId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'POST /enocloud/service/complaint',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          update: {
            action: 'PUT /enocloud/service/complaint',
            loading: true,
            validate: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        computed: {
          disabled() {
            return (
              this.form.data.status?.code === 'UH' ||
              this.form.data.status?.code === 'UF' ||
              this.form.data.status?.code === 'WF' ||
              this.form.data.status?.code === 'S'
            )
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            const res = await this.form[this.form.data.id ? 'update' : 'submit']()
            this.emit('update:model-value', false)
            this.emit('confirm', (res.data[0] as number | undefined) || this.form.data.id)
          }
        }
      }
    }
  }
})
</script>
